// Libraries

import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { get } from "lodash"

// Components

import SEO from "../components/global/SEO"
import PageLayout from "../components/sharedPageLayout"
import ReactHtmlParser from "react-html-parser"
import { mobileSize, desktopSize } from "../helpers/sizeCalculations"
// Styles

/*-----------------------------
Styles
-----------------------------*/

const MediaEnquiries = styled.div`
  margin-bottom: ${desktopSize(79)};
`

const ContactInformation = styled.div`
  p {
    font-family: "gt-walsheim-regular-oblique", sans-serif;
  }
`

const ContactTitle = styled.h2`
  margin-bottom: ${desktopSize(48)};

  @media (max-width: 768px) {
    margin-bottom: ${mobileSize(48)};
  }
`

const ContactContentWrapper = styled.div`
  padding: ${desktopSize(223)} 0 ${desktopSize(30)};
  min-height: 100vh;
  text-align: center;

  @media (max-width: 768px) {
    min-height: 100vh;
    padding: ${mobileSize(209)} 0 ${mobileSize(30)};
  }

  .enquiry-wrapper {
    margin-bottom: ${desktopSize(50)};

    @media (max-width: 768px) {
      margin-bottom: ${mobileSize(45)};
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    h4 {
      margin-bottom: ${desktopSize(9)};

      @media (max-width: 768px) {
        margin-bottom: ${mobileSize(24)};
      }
    }

    a {
      text-decoration: none;
    }
  }
`

/*-----------------------------
Component
-----------------------------*/

class ContactPage extends React.Component {
  constructor(props) {
    super(props)
    // Refs
    // State
    this.state = {}
  }

  componentDidMount() {}

  mediaEnquiries = contactEnquiries => {
    return contactEnquiries.map((elem, key) => {
      return (
        <div key={key} className="enquiry-wrapper">
          <p>{elem.enquiries_title}</p>
          <p>
            <a href={`mailto: ${elem.enquiries_email}`}>
              {elem.enquiries_email}
            </a>
          </p>
        </div>
      )
    })
  }

  /*-----------------------------
  Render
  -----------------------------*/

  render() {
    //Assigning the props value to a variable. So that we avoid writing repeating node the get the adequate props value.
    const contactData = this.props.data.allWordpressPage.nodes[0] || {}

    const pageLayoutProps = {
      ...this.props,
      title: get(contactData, "title"),
      content: get(contactData, "custom.contact_information"),
      enquiries: get(contactData, "custom.contact_enquiries"),
      image: get(contactData, "featured_media"),
      showDividerInResponsive: false,
      showBackButton: true,
    }

    //create a custom subtitle and content structure. This will have different styling from other pages sub title
    const CONTACT_DETAILS = (
      <ContactContentWrapper>
        <ContactTitle>{contactData.custom.contact_sub_title}</ContactTitle>
        <ContactInformation className="wysiwyg ipad">
          {ReactHtmlParser(pageLayoutProps.content)}
        </ContactInformation>
      </ContactContentWrapper>
    )

    return (
      <>
        <SEO title={pageLayoutProps.title} />
        <PageLayout {...pageLayoutProps}>{CONTACT_DETAILS}</PageLayout>
      </>
    )
  }
}

export default ContactPage

/*------------------------------
Graphql Queries
------------------------------*/

export const contactQuery = graphql`
  query contactPageQuerry {
    allWordpressPage(filter: { slug: { eq: "contact-us" } }) {
      nodes {
        title
        custom {
          contact_sub_title
          contact_information
          contact_enquiries {
            enquiries_title
            enquiries_email
          }
        }
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt_text
        }
      }
    }
  }
`
